import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import("@/views/HomeView.vue")
    },
    {
        path: '/productDetail',
        name: 'productDetail',
        component: () => import("@/views/ProductDetail.vue")
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

//  解决路由重复报错的问题
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch((error) => error)
}

const routerReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
    routerReplace.call(this, location).catch((error) => error)
}
export default router
